import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { useState } from 'react';
import logo from "../logo.png"
const Header = () => {

    const [head, setHead] = useState(false);

    const heandleHead = () => {
        setHead(!head)
    }

    return(
        <header className="position-absolute top-0 w-100">
            <div className="container w-100 d-flex justify-content-between align-items-center mt-2">
                <NavLink to="/" >
                    <figure>
                        <img src={logo} className="w-40 h-75" alt="logo portinova"/>
                    </figure>
                </NavLink>
                <ul className='d-flex gap-5 header-pc d-none'>
                    <li>
                        <NavLink to="/">Inicio</NavLink>
                    </li>
                    <li>
                        <a href="#productos">Productos</a>
                    </li>
                    <li>
                        <NavLink to='https://heyzine.com/flip-book/b490e7819f.html' rel="noopener noreferrer nofollow" target="_blank">Catálogo</NavLink>
                    </li>
                    <li>
                        <a href="#contacto">Contacto</a>
                    </li>
                </ul>

                {/****************** Icono hamburguesa *************************/}
                <div onClick={heandleHead} role='button' className='icon-menu'>
                    <FaBars  size={20}/>
                </div>

                {/****************** Menú Mobil *************************/}
                <div className={`${head ? 'header-open' : 'header-close'} container-mobile-header`}>
                    <ul className='text-center ps-0'>
                        <li onClick={heandleHead} className='py-2'>
                            <NavLink href="/">Inicio</NavLink>
                        </li>
                        <li onClick={heandleHead} className='py-2'>
                            <a href="#productos">Productos</a>
                        </li>
                        <li onClick={heandleHead} className='py-2'>
                            <NavLink to='https://heyzine.com/flip-book/b490e7819f.html' rel="noopener noreferrer nofollow" target="_blank">Catálogo</NavLink>
                        </li>
                        <li onClick={heandleHead} className='py-2'>
                            <a href="#contacto">Contacto</a>
                        </li>
                    </ul>
                </div>

            </div>
            
        </header>
    )


}

export default Header